import { ReactNode } from "react";
import Navbar from "./Navbar";
import { useEffect } from 'react';
import SideNavbar from "./SideNavbar";
import { Footer } from "./Footer";
import { userLogged } from "../../services/auth.service";
import { useStateContext } from "../../contexts/ContextProvider";


const Layout = ({ children }: { children: ReactNode }) => {
  const { setUser, user, token} = useStateContext() ?? {}
  
  
  useEffect(() => {
    if (user?.name === '' && token) {
      userLogged().then(({ data }) => {
        setUser?.(data);
      })
    }
  },[])
 
  
  return (
    <div className="main">
      <Navbar />
      <SideNavbar />
      <div className="content">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;