import Layout from "../../components/Layout/Layout"
import blog from '../../assets/images/formats/webp/blog/blog.webp'
import { Breadcrumbs } from "../../components/Breadcrumbs"
import { Link, Outlet } from "react-router-dom"

export const Blog = () => {

    return (
        <Layout>
            <div className="mt-4">
                <div className="blog">
                    <div className="blog__header shadow">
                        <img className="blog__header-img" src={blog} alt="" />
                        <div className="blog__header-text">
                            <h1>Printio.pl</h1>
                            <p>Blog druku 3d</p>
                        </div>
                    </div>
                    <div className="blog__breadcrumbs container">
                        <Breadcrumbs/>
                    </div>
                    <div className="blog__content container">
                        <Outlet />
                    </div>
                </div>
            </div>
        </Layout>
    )

}