import { Link } from "react-router-dom";

const  MenuItem = (prop: {label:string,path:string}) =>{
  const { label, path } = prop;
  return (
    <div className="sidenav__element">
      <Link to={path}>{label}</Link>
    </div>
  );
}
export default MenuItem
