import Layout from "../components/Layout/Layout"
import { FcSearch } from "react-icons/fc"
import { RiTodoFill } from "react-icons/ri"
import { Offers } from "../components/OffersComponents/Offers"
export const Main = () => {


    return (
        <Layout>
            <div className="container ms-0 mt-2 mb-2 main__home">
                <div className="row">
                    <div className="col-12 col-md-4 mt-2">
                        <div className="searchbox">
                            <button tabIndex={-1} type="button" disabled className="searchbox__button">
                                <FcSearch />
                            </button>
                            <input className="searchbox__input" autoComplete="off" placeholder="Search" type="text" aria-autocomplete="list" autoCapitalize="none" spellCheck="false" />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-2">
                        <div className="countrybox">
                            <div id="navbarDropdown" className="dropdown-toggle text-center w-100 countrybox__dropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-pre="">
                                Location
                            </div>
                            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" >
                                    Location-component <RiTodoFill />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Offers />
            </div>
        </Layout>
    )
}