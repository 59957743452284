import axiosClient from "../axios-client"

interface UserSignup {
    name: string,
    surname: string,
    email: string,
    password: string,
    passwordConfirmation: string
}
interface UserLogin {
    email: string,
    password: string,
}

const signup = async (signup: UserSignup) => {
    return await axiosClient.post('/signup', signup)
}
const login = async (credentials: UserLogin) => {
    return await axiosClient.post('/login', credentials)
}
const logout = async () => {
    return await axiosClient.post('/logout')
}
const userLogged = async () => {
    return await axiosClient.get('/user')
}
const loginAdminCms = async (credentials: UserLogin) => {
    return await axiosClient.post('/login-admin-cms', credentials)
}

export {signup,login,logout,userLogged, loginAdminCms}