// Define the formatting functions
// Define the properties and values
const cssIntelliSense = {
  'font-family': ['Arial', 'Helvetica', 'Times New Roman', 'Verdana', 'Tahoma'],
  "background-color": ["transparent", "aliceblue", "antiquewhite", "aqua", "aquamarine", "azure", "beige", "bisque", "black", "blanchedalmond", "blue", "blueviolet", "brown", "burlywood", "cadetblue", "chartreuse", "chocolate", "coral", "cornflowerblue", "cornsilk", "crimson", "cyan", "darkblue", "darkcyan", "darkgoldenrod", "darkgray", "darkgrey", "darkgreen", "darkkhaki", "darkmagenta", "darkolivegreen", "darkorange", "darkorchid", "darkred", "darksalmon", "darkseagreen", "darkslateblue", "darkslategray", "darkslategrey", "darkturquoise", "darkviolet", "deeppink", "deepskyblue", "dimgray", "dimgrey", "dodgerblue", "firebrick", "floralwhite", "forestgreen", "fuchsia", "gainsboro", "ghostwhite", "gold", "goldenrod", "gray", "grey", "green", "greenyellow", "honeydew", "hotpink", "indianred", "indigo", "ivory", "khaki", "lavender", "lavenderblush", "lawngreen", "lemonchiffon", "lightblue", "lightcoral", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightgrey", "lightgreen", "lightpink", "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightslategrey", "lightsteelblue", "lightyellow", "lime", "limegreen", "linen", "magenta", "maroon", "mediumaquamarine", "mediumblue", "mediumorchid", "mediumpurple", "mediumseagreen", "mediumslateblue", "mediumspringgreen", "mediumturquoise", "mediumvioletred", "midnightblue", "mintcream", "mistyrose", "moccasin", "navajowhite", "navy", "oldlace", "olive", "olivedrab", "orange", "orangered", "orchid", "palegoldenrod", "palegreen", "paleturquoise", "palevioletred", "papayawhip", "peachpuff", "peru", "pink", "plum", "powderblue", "purple", "rebeccapurple", "red", "rosybrown", "royalblue", "saddlebrown", "salmon", "sandybrown", "seagreen", "seashell", "sienna", "silver", "skyblue", "slateblue", "slategray", "slategrey", "snow", "springgreen", "steelblue", "tan", "teal", "thistle", "tomato", "turquoise", "violet", "wheat", "white", "whitesmoke", "yellow", "yellowgreen"],
  "color": ["aliceblue", "antiquewhite", "aqua", "aquamarine", "azure", "beige", "bisque", "black", "blanchedalmond", "blue", "blueviolet", "brown", "burlywood", "cadetblue", "chartreuse", "chocolate", "coral", "cornflowerblue", "cornsilk", "crimson", "cyan", "darkblue", "darkcyan", "darkgoldenrod", "darkgray", "darkgrey", "darkgreen", "darkkhaki", "darkmagenta", "darkolivegreen", "darkorange", "darkorchid", "darkred", "darksalmon", "darkseagreen", "darkslateblue", "darkslategray", "darkslategrey", "darkturquoise", "darkviolet", "deeppink", "deepskyblue", "dimgray", "dimgrey", "dodgerblue", "firebrick", "floralwhite", "forestgreen", "fuchsia", "gainsboro", "ghostwhite", "gold", "goldenrod", "gray", "grey", "green", "greenyellow", "honeydew", "hotpink", "indianred", "indigo", "ivory", "khaki", "lavender", "lavenderblush", "lawngreen", "lemonchiffon", "lightblue", "lightcoral", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightgrey", "lightgreen", "lightpink", "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightslategrey", "lightsteelblue", "lightyellow", "lime", "limegreen", "linen", "magenta", "maroon", "mediumaquamarine", "mediumblue", "mediumorchid", "mediumpurple", "mediumseagreen", "mediumslateblue", "mediumspringgreen", "mediumturquoise", "mediumvioletred", "midnightblue", "mintcream", "mistyrose", "moccasin", "navajowhite", "navy", "oldlace", "olive", "olivedrab", "orange", "orangered", "orchid", "palegoldenrod", "palegreen", "paleturquoise", "palevioletred", "papayawhip", "peachpuff", "peru", "pink", "plum", "powderblue", "purple", "rebeccapurple", "red", "rosybrown", "royalblue", "saddlebrown", "salmon", "sandybrown", "seagreen", "seashell", "sienna", "silver", "skyblue", "slateblue", "slategray", "slategrey", "snow", "springgreen", "steelblue", "tan", "teal", "thistle", "tomato", "turquoise", "violet", "wheat", "white", "whitesmoke", "yellow", "yellowgreen"],
  "font-size": ["12px", "14px", "16px", "18px", "20px", "24px", "28px"],
  "font-weight": ["normal", "bold", "bolder", "lighter"],
  "text-align": ["left", "right", "center", "justify"],
  "text-decoration": ["none", "underline", "overline", "line-through"],
  "text-transform": ["none", "capitalize", "uppercase", "lowercase"],
  "border": ["none", "solid", "dashed", "dotted"],
  "border-color": ["transparent", "aliceblue", "antiquewhite", "aqua", "aquamarine", "azure", "beige", "bisque", "black", "blanchedalmond", "blue", "blueviolet", "brown", "burlywood", "cadetblue", "chartreuse", "chocolate", "coral", "cornflowerblue", "cornsilk", "crimson", "cyan", "darkblue", "darkcyan", "darkgoldenrod", "darkgray", "darkgrey", "darkgreen", "darkkhaki", "darkmagenta", "darkolivegreen", "darkorange", "darkorchid", "darkred", "darksalmon", "darkseagreen", "darkslateblue", "darkslategray", "darkslategrey", "darkturquoise", "darkviolet", "deeppink", "deepskyblue", "dimgray", "dimgrey", "dodgerblue", "firebrick", "floralwhite", "forestgreen", "fuchsia", "gainsboro", "ghostwhite", "gold", "goldenrod", "gray", "grey", "green", "greenyellow", "honeydew", "hotpink", "indianred", "indigo", "ivory", "khaki", "lavender", "lavenderblush", "lawngreen", "lemonchiffon", "lightblue", "lightcoral", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightgrey", "lightgreen", "lightpink", "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightslategrey", "lightsteelblue", "lightyellow", "lime", "limegreen", "linen", "magenta", "maroon", "mediumaquamarine", "mediumblue", "mediumorchid", "mediumpurple", "mediumseagreen", "mediumslateblue", "mediumspringgreen", "mediumturquoise", "mediumvioletred", "midnightblue", "mintcream", "mistyrose", "moccasin", "navajowhite", "navy", "oldlace", "olive", "olivedrab", "orange", "orangered", "orchid", "palegoldenrod", "palegreen", "paleturquoise", "palevioletred", "papayawhip", "peachpuff", "peru", "pink", "plum", "powderblue", "purple", "rebeccapurple", "red", "rosybrown", "royalblue", "saddlebrown", "salmon", "sandybrown", "seagreen", "seashell", "sienna", "silver", "skyblue", "slateblue", "slategray", "slategrey", "snow", "springgreen", "steelblue", "tan", "teal", "thistle", "tomato", "turquoise", "violet", "wheat", "white", "whitesmoke", "yellow", "yellowgreen"],
  "border-radius": ["0", "2px", "4px", "6px", "8px", "10px"],
  "padding": ["0", "2px", "4px", "6px", "8px", "10px"],
  "margin": ["0", "2px", "4px", "6px", "8px", "10px"],
  "display": ["block", "inline", "inline-block", "flex", "grid", "none"],
  "height": ["auto", "100px", "200px", "300px", "400px", "500px"],
  "width": ["auto", "100px", "200px", "300px", "400px", "500px"],
  "background-image": ["url('image1.jpg')", "url('image2.jpg')", "url('image3.jpg')"],
  'background-position': ['left top', 'center center', 'right bottom'],
  'background-size': ['cover', 'contain'],
  'position': ['static', 'relative', 'absolute', 'fixed'],
  'float': ['left', 'right', 'none'],
  'z-index': ['1', '2', '3', '4', '5'],
  'opacity': ['0.5', '0.6', '0.7', '0.8', '0.9'],
  'transition': ['all 0.3s ease', 'opacity 1s ease-in-out'],
  'top': ['10px', '20px', '30px', '40px', '50px', '0', '50%'],
  'bottom': ['10px', '20px', '30px', '40px', '50px', '0', '50%'],
  'left': ['10px', '20px', '30px', '40px', '50px', '0', '50%'],
  'right': ['10px', '20px', '30px', '40px', '50px', '0', '50%'],
  'flex-direction': ['row', 'row-reverse', 'column', 'column-reverse'],
  'flex-wrap': ['nowrap', 'wrap', 'wrap-reverse'],

};
function bold() {
  document.execCommand("bold", false, null);
}

function italic() {
  document.execCommand("italic", false, null);
}

function underline() {
  document.execCommand("underline", false, null);
}

function strike() {
  document.execCommand("strikeThrough", false, null);
}

function link() {
  const url = prompt("Enter the URL:");
  document.execCommand("createLink", false, url);
}

function code() {
  document.execCommand("formatBlock", false, "<pre>");
}

function quote() {
  document.execCommand("formatBlock", false, "<blockquote>");
}
function createHeader() {
  const header = document.createElement("h1");
  header.textContent = "Header";
  header.classList.add("block");
  header.setAttribute('name', 'header');
  header.onclick = (e) => openPopup(e);
  header.addEventListener("dragstart", dragStart);
  header.addEventListener("dragend", dragEnd);
  return header;
}
function createParagraph() {
  const paragraph = document.createElement("p");
  paragraph.textContent = "Lorem ipsum dolor sit amet.";
  paragraph.classList.add("block");
  paragraph.onclick = (e) => openPopup(e);
  paragraph.addEventListener("dragstart", dragStart);
  paragraph.addEventListener("dragend", dragEnd);
  return paragraph;
}
function createImage() {
  const image = document.createElement("img");
  image.setAttribute("src", "https://picsum.photos/200/300");
  image.classList.add("block");
  image.onclick = (e) => openPopup(e);
  image.addEventListener("dragstart", dragStart);
  image.addEventListener("dragend", dragEnd);
  return image;
}
function createDiv() {
  const div = document.createElement("div");
  div.classList.add("block");
  div.setAttribute('contentEditable', "true");
  div.textContent = "Div";
  div.onclick = (e) => openPopup(e);
  div.addEventListener("dragstart", dragStart);
  div.addEventListener("dragend", dragEnd);
  div.addEventListener("dragover", (event) => {
    event.preventDefault();
  });
  div.addEventListener("drop", (event) => {
    event.preventDefault();

  });
  return div;
}
function createDropdown() {
  const dropdown = document.createElement("div");
  dropdown.classList.add("block");
  dropdown.classList.add('dropdown');
  dropdown.innerHTML = `
    <select>
      <option value="">Option 1</option>
    </select>
  `;
  dropdown.onclick = (e) => {
    if (e.target.type) {
      e.target.options[0].value = 'dupa';
      e.target.options[0].textContent = 'dupa';
    }
    else {
      openPopup(e);
    }
  }
  dropdown.onchange = (e) => console.log(e.target.value)
  dropdown.addEventListener("dragstart", dragStart);
  dropdown.addEventListener("dragend", dragEnd);
  return dropdown;
}

function dragStart(event) {
  this.classList.add("dragging");
  event.dataTransfer.setData("text/plain", event.target.innerHTML);
}

function dragEnd(event) {
  this.classList.remove("dragging");
}
function buildDomTree(root) {
  let tree = { tagName: root.tagName, children: [] };

  // Loop through each child node of the root element
  for (let i = 0; i < root.children.length; i++) {
    let child = root.children[i];

    // If the child is an element node, recursively build its subtree
    if (child.nodeType === Node.ELEMENT_NODE) {
      let subtree = buildDomTree(child);
      tree.children.push(subtree);
    }
  }

  return tree;
}
function dropHandler(event) {
  event.preventDefault();
  let component = null;
  const type = event.dataTransfer.getData("text/plain");

  // Add the dropped element to the array

  if (type === "header") {
    component = createHeader();
  } else if (type === "paragraph") {
    component = createParagraph();
  } else if (type === "image") {
    component = createImage();
  } else if (type === "div") {
    component = createDiv();
  }
  else if (type === "dropdown") {
    component = createDropdown();
  }
  if (event.target.getAttribute('contenteditable')) {
    event.target.appendChild(component);

  }
  //console.log(droppedElements)
}
function exportCode() {
  const canvas = document.querySelector(".page");
  const htmlCode = canvas.innerHTML;
  console.log('<div>' + htmlCode + '</div>')
  //document.querySelector("#code").value = htmlCode;
}
let currentComponent;

function openPopup(e) {
  e.preventDefault();
  currentComponent = e.target;
  let popup = document.getElementById('popup');
  let css = document.getElementById('css');
  const currentStyle = currentComponent.style.cssText.split(';')
  let currentStyleText = "";
  currentStyle.forEach(element => {
    if(element){
      currentStyleText += element.trim() + ';';
      currentStyleText += '\n';
    }
  });
  css.value = currentStyleText;
  popup.style.display = 'block';
}
function saveCssPopup() {
  let popup = document.getElementById('popup');
  let css = document.getElementById('css');
  currentComponent.style.cssText += css.value;
  css.value = "";
  popup.style.display = 'none';
}
function dismissCssPopup() {
  let css = document.getElementById('css');
  let popup = document.getElementById('popup');
  css.value = "";
  popup.style.display = 'none';
}
function eraseCssPopup() {
  let css = document.getElementById('css');
  let popup = document.getElementById('popup');
  currentComponent.style.cssText = '';
  css.value = "";
  popup.style.display = 'none';
}

function filterProperties(cssInput) {
  return Object.entries(cssIntelliSense).filter((property) => property[0].includes(cssInput));
}
function showCssIntellisense(cssIntelliSense) {
  const intellisense = document.getElementById("intellisense");
  const listItems = cssIntelliSense.map(property => {
    const values = property[1].map(value => `<li onclick="testowanie()">${value}</li>`).join("");
    return `<li><b>${property[0]}:</b><ul>${values}</ul></li>`;
  }).join("");

  intellisense.innerHTML = `<ul>${listItems}</ul>`;
  intellisense.style.display = "block";
}



const handleOnCssInput = (event) => {
  const intellisense = document.getElementById("intellisense");
  const cssInput = event.target.value;
  const filteredProperties = filterProperties(cssInput);

  if (cssInput.length > 0) {
    showCssIntellisense(filteredProperties);
  } else {
    intellisense.style.display = "none";
  }

}


let selectedIndex = -1;
let suggestionType = null;
let globalInputPropery = '';
const compositeHandleOnCssInput = (event) => {
  const intellisense = document.getElementById("intellisense");
  const cssInput = event.target.value;
  const inputLines = cssInput.split('\n');
  const lastLine = inputLines[inputLines.length - 1];

  const inputParts = lastLine.split(':');
  const inputProperty = inputParts[0].trim();
  const inputPropertyValue = inputParts[1]?.trim();
  let suggestions = [];
  if (inputProperty && !inputPropertyValue) {
    suggestions = Object.keys(cssIntelliSense).filter(property => property.startsWith(inputProperty));
    suggestionType = 'property';
  } else if (inputProperty && inputPropertyValue) {
    const matchingProperties = Object.keys(cssIntelliSense).filter(property => property.startsWith(inputProperty));
    const matchingValues = cssIntelliSense[inputProperty]?.filter(value => value.startsWith(inputPropertyValue)) || [];

    suggestions = [...matchingProperties, ...matchingValues];
    suggestionType = 'value';//matchingProperties.includes(inputProperty) ? 'property' : 'value';
  }
  globalInputPropery = inputProperty;

  if (cssInput.length > 0) {
    renderSuggestions(suggestions);
  } else {
    intellisense.style.display = "none";
  }
}

const handleCssKeydown = (event) => {
  const intellisense = document.getElementById("intellisense");
  if (event.key === 'ArrowUp' && selectedIndex > 0) {
    event.preventDefault();
    selectedIndex--;
    highlightSuggestion(selectedIndex);
  } else if (event.key === 'ArrowDown' && selectedIndex < intellisense.children.length - 1) {
    event.preventDefault();
    selectedIndex++;
    highlightSuggestion(selectedIndex);
  } else if (event.key === 'Tab' && selectedIndex !== -1) {
    event.preventDefault();
    chooseSuggestion();
  }
}
function highlightSuggestion(index) {
  const intellisense = document.getElementById("intellisense");
  const cssInput = document.getElementById("css");
  const children = intellisense.children;
  const inputLines = cssInput.value.split('\n');
  const lastLine = inputLines[inputLines.length - 1];
  for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (i === index) {
          child.classList.add('selected');
          const newLine = suggestionType === 'property' ? `${child.textContent}: ` : `${globalInputPropery}: ${child.textContent}`;
          inputLines[inputLines.length - 1] = newLine;
          cssInput.value = inputLines.join('\n');
      } else {
          child.classList.remove('selected');
      }
  }
}

function renderSuggestions(suggestions) {
  const intellisense = document.getElementById("intellisense");
  if (suggestionType == 'value') {
    suggestions.shift();
  }
  intellisense.innerHTML = '';
  selectedIndex = -1;

  if (suggestions.length === 0) {
    intellisense.style.display = 'none';
  } else {
    intellisense.style.display = 'block';
    suggestions.forEach(suggestion => {
      const suggestionElement = document.createElement('li');
      suggestionElement.textContent = suggestion;
      intellisense.appendChild(suggestionElement);
    });
  }
}

function chooseSuggestion() {
  const intellisense = document.getElementById("intellisense");
  const cssInput = document.getElementById("css");
  const inputLines = cssInput.value.split('\n');
  const selectedSuggestion = intellisense.children[selectedIndex].textContent;
  const newLine = suggestionType === 'property' ? `${selectedSuggestion}: ` : `${globalInputPropery}: ${selectedSuggestion}`;
  inputLines[inputLines.length - 1] = newLine;
  cssInput.value = inputLines.join('\n');
  if(suggestionType == 'value'){
    cssInput.value += ';';
    cssInput.value += '\n';
  }
  intellisense.style.display = 'none';
  selectedIndex = -1;
  suggestionType = null;
}



const handleBuildDomTree = () => {
  const root = document.getElementById('content')
  let domTree = buildDomTree(root);
  let jsonString = JSON.stringify(domTree, null, 2);
  document.getElementById('domTree').textContent = jsonString;
}

export const blogjs = {
  bold, italic, underline, strike, link, code,
  quote, dropHandler, exportCode, openPopup, saveCssPopup,
  dismissCssPopup, eraseCssPopup, handleBuildDomTree, handleOnCssInput,
  compositeHandleOnCssInput, handleCssKeydown
}
