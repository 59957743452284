import { createContext, ReactNode, useContext, useState } from "react";
import { getOffers } from "../services/offer.service";
import useIsMobile from "../hooks/useIsMobile";


interface User {
    name: string
    surname: string
}
interface AdminCmsAuth {
    adminCmsToken: string
    setAdminCmsToken: (user: string) => void
}
interface Auth extends AdminCmsAuth {
    user: User,
    token: string,
    setUser: (user: User) => void
    setToken: (user: string) => void
}

interface Menu {
    isMenuOpen:boolean
    setIsMenuOpen?: (value:boolean) => void
    isMobile:boolean

}

const StateContext = createContext<Auth & Menu | null>(null)

export const ContextProvider = ({ children }: { children?: ReactNode }) => {
    const [user, setUser] = useState<User>({
        name: '',
        surname: ''
    });
    const [token, _setToken] = useState<string>(localStorage.getItem('ACCESS_TOKEN') ?? '')
    const [adminCmsToken, _setAdminCmsToken] = useState<string>(localStorage.getItem('ADMIN_TOKEN') ?? '')
    const [isMenuOpen, _setIsMenuOpen] = useState(true);
    const isMobile = useIsMobile();


    const setToken = (token: string) => {
        _setToken(token);
        if (token) {
            localStorage.setItem('ACCESS_TOKEN', token);
        }
        else {
            localStorage.removeItem('ACCESS_TOKEN');
        }
    }
    const setAdminCmsToken = (adminCmsToken: string) => {
        _setAdminCmsToken(adminCmsToken);
        if (adminCmsToken) {
            localStorage.setItem('ADMIN_TOKEN', adminCmsToken);
        }
        else {
            localStorage.removeItem('ADMIN_TOKEN');
        }
    }
    

    const setIsMenuOpen = (value:boolean) => {
        _setIsMenuOpen(value)
    }
    
    return (

        <StateContext.Provider value={{
            user,
            token,
            adminCmsToken,
            setUser,
            setToken,
            setAdminCmsToken,
            isMenuOpen,
            setIsMenuOpen,
            isMobile
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => {
    const context = useContext(StateContext);
    return context;
};