import MenuItem from "./MenuItem";
import { SidebarData } from "./MenuData";

const SideNavbar = () => {

  return (
    <div className="sidenav">
      <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">IN3dNet</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          {SidebarData ? SidebarData.map((item, index) => (
            <MenuItem label={item.label} path={item.path} key={index} />
          )) : null}
        </div>
      </div>
    </div>
  );
}
export default SideNavbar;