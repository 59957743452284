
import { useEffect } from "react"
import { OffersCard } from "./OffersCard"
import { useOfferContext } from "../../contexts/OffersContext"
import { Loader } from "../../hooks/scrollLoading/Loader"
import { useScrollLoading } from "../../hooks/useScrollLoading"
import { OfferInformation } from "./OfferInformation"
import { useStateContext } from "../../contexts/ContextProvider"


export const Offers = () => {
    const { allOffers, _setAllOffers, _setActiveOffer,activeOffer, _setShow} = useOfferContext() ?? {};
    const { isMobile } = useStateContext() ?? {};
    const {
        isLoading,
        loadMoreCallback,
        hasdynamicData,
        dynamicData,
        isLastPage,
    } = useScrollLoading([],
        {
            'serviceName': 'offer',
            'functinoName': 'getOffers'
        }
    );
    useEffect(() => {
        if(hasdynamicData){
            _setAllOffers?.(dynamicData)
        }
    },[dynamicData,hasdynamicData])

    const setActiveOffer = (offerId: number) => {
        _setActiveOffer?.(allOffers?.find(({id}) => id == offerId))
        if(isMobile){
            _setShow?.(true);
        }
    }
    return (
        <div className="offers container mb-5 " >
            <div className="row">
                <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-9 offers__information">
                    <OfferInformation/>
                </div>
                <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 offers__list">
                    {hasdynamicData ? dynamicData.map((offert, index) => (
                        <OffersCard
                            title={offert?.title}
                            printerName={offert?.printer_name}
                            description={offert?.description}
                            checkButton="CHECK OFFERT"
                            offertDate={offert?.created_at}
                            key={index}
                            onClick={ () => setActiveOffer(offert.id)}
                        />
                    )) : null
                    }
                    <Loader
                        isLoading={isLoading}
                        isLastPage={isLastPage}
                        loadMoreCallback={loadMoreCallback}
                    />
                </div>
            </div>
        </div>
    )
}