import { useRef } from "react";
import { signup } from "../../services/auth.service";
import { useStateContext } from "../../contexts/ContextProvider";
import { Link } from "react-router-dom";
import particles from '../../assets/images/formats/webp/forms/particles.webp'

export const Signup = () => {

    const { setUser, setToken } = useStateContext()??{};

    const nameInputRef = useRef<HTMLInputElement>(null);
    const surnameInputRef = useRef<HTMLInputElement>(null);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const passwordConfirmationInputRef = useRef<HTMLInputElement>(null);

    const onSignUp = (ev: any) => {
        ev.preventDefault();
        const payload = {
            name: nameInputRef.current?.value ?? '',
            surname: surnameInputRef.current?.value ?? '',
            email: emailInputRef.current?.value ?? '',
            password: passwordInputRef.current?.value ?? '',
            passwordConfirmation: passwordConfirmationInputRef.current?.value ?? '',
        }
        signup(payload).then(({data}) =>{
            setUser?.(data.user);
            setToken?.(data.token)
        })
    }

    return (
        <div className="container relative signup-login">
            <div className="absolute signup-login__content">
            <img src={particles} alt="" />
            <form onSubmit={onSignUp} className="signup-login__form">
                <h1>Sign Up</h1>
                <div className="row">
                    <div className="col-12 col-md-6 signup-login-others_field">
                        <label htmlFor="name">Name:</label>
                        <input type="text" ref={nameInputRef} placeholder="Enter your name" className="form-control" />
                    </div>
                    <div className=" col-12 col-md-6 signup-login-others_field">
                        <label htmlFor="name">Surname:</label>
                        <input type="text" ref={surnameInputRef} placeholder="Enter your surname" className="form-control" />
                    </div>
                    <div className="col-12 signup-login-others_field">
                        <label htmlFor="email">Email:</label>
                        <input type="email" ref={emailInputRef} placeholder="Enter your email address" className="form-control" 
                            pattern="^[a-zA-Z0-9.!#$%&`*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)" 
                        />
                    </div>
                    <div className="col-12 signup-login-others_field">
                        <label htmlFor="email">Password:</label>
                        <input type="password" ref={passwordInputRef} placeholder="Enter your password" className="form-control"  />
                    </div>
                    <div className="col-12 signup-login-others_field">
                        <label htmlFor="email">Password Confirmation:</label>
                        <input type="password" ref={passwordConfirmationInputRef} placeholder="Retype your password" className="form-control"/>
                    </div>
                    <div className="signup-login-others_field">
                        <button type="submit" className="w-100">Sign up</button>
                        <span>
                           Already have an account <Link to={"/login"}>Login</Link>
                        </span>
                    </div>
                </div>
            </form>
            </div>
        </div>
    )
}