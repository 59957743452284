import { useRef } from "react";
import { Navigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import { loginAdminCms } from "../../../services/auth.service";


export const AdminBlogLogin = () => {

    const { setUser, setAdminCmsToken,adminCmsToken } = useStateContext()??{};
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);

    if (adminCmsToken){
        return <Navigate to="/admin/blog/cms/CMS" />
    }
    const onLogin = (ev: any) => {
        ev.preventDefault();
        const payload = {
            email: emailInputRef.current?.value ?? '',
            password: passwordInputRef.current?.value ?? '',
        }
        loginAdminCms(payload).then(({data}) =>{
            console.log(data)
            setUser?.(data.user);
            setAdminCmsToken?.(data.token)  
        }).catch(err => {
            console.log(err.data.message)
        })
    }
    return (    
        <div className="container signup-login">
            <form onSubmit={onLogin}>
                <h1>Login Admin CMS</h1>
                <div className="row">
                    <div className="col-12 signup-login-others_field">
                        <label htmlFor="email">Email:</label>
                        <input type="email" ref={emailInputRef} placeholder="Enter your email address" className="form-control" 
                            pattern="^[a-zA-Z0-9.!#$%&`*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)" 
                        />
                    </div>
                    <div className="col-12 signup-login-others_field">
                        <label htmlFor="email">Password:</label>
                        <input type="password" ref={passwordInputRef} placeholder="Enter your password" className="form-control"  />
                    </div>

                    <div className="signup-login-others_field">
                        <button type="submit" className="w-100">Login</button>
                    </div>
                </div>
            </form>
        </div>
    )
}