
type CardProsp = {
    title: string
    description: string
    printerName: string
    checkButton: string
    offertDate: string
    onClick?: React.MouseEventHandler<HTMLDivElement>

}

export const OffersCard = ({ title, description, printerName, checkButton, offertDate, onClick }: CardProsp) => {


    return (
        <div className="offers__card mt-2" onClick={onClick}>
            <div className="offers__card-content offers__card-shadow-box">
                <h1>{title}</h1>
                <div className="offers__card-links">
                    <div className="offers__card-image">
                        <a href="">
                            <img src="https://www.memtest86.com/img/main-img-1.png" alt="My Freedom Smoke" title="My Freedom Smoke" />
                        </a>
                    </div>
                    <div className="offers__card-shopname">
                        <a href="">
                            <span>{printerName}</span>
                        </a>
                    </div>
                </div>
                <div className="offers__card-shophead">
                    <h3 className="offers__card-title">
                        <a href="#">{title}</a>
                    </h3>
                    <div className="offers__card-description">
                        {description}
                        <span className="offers__card-description-more">...
                            <a href="#">
                                More 
                            </a>
                        </span>
                    </div>
                </div>
                <div className="offers__card-offert">
                    <a href="#" className="offers__card-offert-check" >
                        {checkButton}
                    </a>
                    <p className="offers__card-offert-date">
                        Added {offertDate}
                    </p>
                </div>
            </div>
        </div>

    )
}