import { useRef, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { login } from "../../services/auth.service";
import { Link } from "react-router-dom";
import particles from '../../assets/images/formats/webp/forms/particles.webp'

export const Login = () => {

    const { setUser, setToken } = useStateContext() ?? {};
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const [errorLog, setErrorLog] = useState<string>("");

    const onLogin = (ev: any) => {
        ev.preventDefault();
        const payload = {
            email: emailInputRef.current?.value ?? '',
            password: passwordInputRef.current?.value ?? '',
        }
        login(payload).then(({ data }) => {
            console.log(data)
            setUser?.(data.user);
            setToken?.(data.token)
        }).catch(err => {
            console.log(err.data.message)
        })
    }
    return (
        <div className=" container relative signup-login">
            <div className="absolute signup-login__content">
                <img src={particles} alt="" />
                <form onSubmit={onLogin} className="signup-login__form">
                    <h1>Login</h1>
                    <div className="row">
                        <div className="col-12 signup-login-others_field">
                            <label htmlFor="email">Email:</label>
                            <input type="email" ref={emailInputRef} placeholder="Enter your email address" className="form-control" pattern="^[a-zA-Z0-9.!#$%&`*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)" />
                        </div>
                        <div className="col-12 signup-login-others_field">
                            <label htmlFor="email">Password:</label>
                            <input type="password" ref={passwordInputRef} placeholder="Enter your password" className="form-control" />
                        </div>
                        <div className="signup-login-others_field">
                            <button type="submit" className="w-100">Login</button>
                            <span>
                                Not Registred?<Link to={"/signup"}>Create an account</Link>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}