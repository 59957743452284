import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {RouterProvider} from "react-router-dom";
import router from './router';
import { ContextProvider } from './contexts/ContextProvider';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import "./styles/_main.scss"
import Compose from "./utils/Compose"
import { OfferProvider } from './contexts/OffersContext';
import "core-js/stable";
import "regenerator-runtime/runtime";




ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Compose components={[ContextProvider,OfferProvider]}>
      <RouterProvider router={router} />
    </Compose>
  </React.StrictMode>,
)
