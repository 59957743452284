import { Outlet } from 'react-router-dom'; 
import { useStateContext } from '../contexts/ContextProvider';
import { Navigate } from "react-router-dom";
import Layout from '../components/Layout/Layout';


export const DefaultLayout = () => {
    const {token} = useStateContext()??{}

    if (!token){
        return <Navigate to="/login" />
    }
   

    return(
        <Layout>
            <Outlet/>
        </Layout>
    )
}