import { Outlet } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Navigate } from "react-router-dom";
import Layout from '../components/Layout/Layout';
export const GuestLayout = () => {
    const { token } = useStateContext() ?? {};

    if (token) {
        return <Navigate to="/" />
    }
    return (
        <Layout>
            <Outlet />
        </Layout>
    )
}