import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useReducer, useState } from "react";
import { getOffers } from "../services/offer.service";


interface Offer {
    id: number
    title: string
    created_at: string
}

interface Offers {
    allOffers?: []
    pagination?: [{
        url?:string
        label?:string
        active:boolean
    }]
    _setAllOffers: (offer?:any)=> void
    activeOffer: any
    _setActiveOffer: (offer?:any) => void
    show: boolean
    _setShow: (state:boolean) => void
    setOffers: (url?:string) => void

}

const OfferContext = createContext<Offers | null>(null)


export const OfferProvider = ({ children }: { children?: ReactNode }) => {
    const [allOffers, setAllOffers] = useState<[]>([]);
    const [pagination, setPagination] = useState<any>();
    const [activeOffer, setActiveOffer] = useState<Offer>()
    const [show, setShow] = useState(false);

    const _setShow = (state:boolean) => {
        setShow(state);
    }
    const _setActiveOffer = (offer:any) => {
        setActiveOffer(offer);
    }
    const _setAllOffers = (offer:any) =>{
        setAllOffers(offer)
    }
    const setOffers = (url?:string) => {
        getOffers(url).then(({data})=>{
            setAllOffers(data.data);
            setPagination(data.links)
        });
    }
    
    return (
        <OfferContext.Provider value={{
            allOffers,
            pagination,
            _setAllOffers,
            setOffers,
            activeOffer,
            _setActiveOffer,
            show,
            _setShow
        }}>
            {children}
        </OfferContext.Provider>
    )
}

export const useOfferContext = () => {
    const context = useContext(OfferContext);
    return context;
};