import Layout from "../../components/Layout/Layout"



export const NotFound = () => {


    return(
        <Layout>
            Nie znaleziono 
        </Layout>
    )
}