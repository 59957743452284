
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
export const BlogList = () => {
    const { id } = useParams()
    return (
        
            <Link to={'rrrr'}>ddd</Link>


    )

}