import { Navigate, Outlet } from "react-router-dom"
import { useStateContext } from "../../../../contexts/ContextProvider";

export const BlogMain = () => {
    


    return (
        <div>
            <Outlet/>
        </div>
    )
}