
import { useParams } from "react-router-dom"
export const Testowanie = () => {
    const { id } = useParams()
    return (
        <div>
            <h1>test {id}</h1>
        </div>
    )

}