import { useRef } from "react";
import { newOffer } from "../../services/offer.service";



export const NewOffers = () => {
    const titleInputRef = useRef<HTMLInputElement>(null);
    const printerNameInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const onNewOffert = () => {
        const payload = {
            title: titleInputRef.current?.value ?? '',
            printer_name: printerNameInputRef.current?.value ?? '',
            description: descriptionInputRef.current?.value ?? '',
        }
        newOffer(payload).then((data) => {
            console.log(data)
        }).catch(err => {
            console.log(err.data.message)
        })
    }

    return (
        <div className="offers__card offers__create">
            <div className="container mt-2 mb-2">
                <div className="offers__card-content offers__card-shadow-box">
                    <h1>Create new offer</h1>
                    <div className="offers__card-links">
                        <form >
                            <div className="row">
                                <div className="col-12 col-md-6 signup-login-others_field">
                                    <label htmlFor="name">Title</label>
                                    <input type="text" ref={titleInputRef} placeholder="Enter title" className="form-control" />
                                </div>
                                <div className="col-12 col-md-6 signup-login-others_field">
                                    <label htmlFor="name">Printer Name:</label>
                                    <input type="text" ref={printerNameInputRef} placeholder="Enter printer name" className="form-control" />
                                </div>
                                <div className=" col-12 col-md-6 signup-login-others_field">
                                    <label htmlFor="name">Description:</label>
                                    <input type="text" ref={descriptionInputRef} placeholder="Enter your description" className="form-control" />
                                </div>
                                <div className="offers__card-offert text-end">
                                    <button className="offers__card-offert-check border-0" onClick={onNewOffert} type="reset">
                                        Create Offert
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}