import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Breadcrumbs = () => {
    const location = useLocation();
    let currentLink = '';
    const breadcrumbs = location.pathname.split('/').filter(n=>n)?.map((crumb,key)=>{
        currentLink += `/${crumb}`
        return (
                <Link to={currentLink} key={key}>{crumb}/</Link> 
        );
    })
    return (
        <div>
            {breadcrumbs}
        </div>
    );
}