
import { useCallback, useState, useRef } from "react";

export interface useScrollLoading {
    isLoading: boolean;
    loadMoreCallback: (el: HTMLDivElement) => void;
    hasdynamicData: boolean;
    dynamicData: any[];
    isLastPage: boolean;
}

export const useScrollLoading = (data: [], service: any): useScrollLoading => {
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasdynamicData, setHasdynamicData] = useState(false);
    const [dynamicData, setdynamicData] = useState<any[]>(data);
    const [isLastPage, setIsLastPage] = useState(false);
    const observerRef = useRef<IntersectionObserver>();
    const loadMoreTimeout: NodeJS.Timeout = setTimeout(() => null, 200);
    const loadMoreTimeoutRef = useRef<NodeJS.Timeout>(loadMoreTimeout);

    const handleObserver = useCallback(
        (entries: any[]) => {
            const target = entries[0];
            if (target.isIntersecting) {
                setIsLoading(true);
                clearTimeout(loadMoreTimeoutRef.current);
                loadMoreTimeoutRef.current = setTimeout(async () => {
                    const module = await require('../services/' + service.serviceName + '.service');
                    console.log(module)
                    module[service.functinoName](`/${service.functinoName}?page=${page}`).then((resp: any) => {
                        setPage(page + 1);
                        const newdata = resp?.data["data"];
                        if (newdata?.length) {
                            const newdynamicData = [...dynamicData, ...newdata];
                            setdynamicData(newdynamicData);
                            setIsLastPage(newdynamicData?.length === resp?.data["total"]);
                            setHasdynamicData(true);
                            setIsLoading(false);
                        }
                    });
                }, 200);
            }
        },
        [loadMoreTimeoutRef, setIsLoading, page, dynamicData]
    );

    const loadMoreCallback = useCallback(
        (el: HTMLDivElement) => {
            if (isLoading) return;
            if (observerRef.current) observerRef.current.disconnect();

            const option: IntersectionObserverInit = {
                root: null,
                rootMargin: "0px",
                threshold: 1.0,
            };
            observerRef.current = new IntersectionObserver(handleObserver, option);

            if (el) observerRef.current.observe(el);
        },
        [handleObserver, isLoading]
    );

    return {
        isLoading,
        loadMoreCallback,
        hasdynamicData,
        dynamicData,
        isLastPage,
    };
};