import { useStateContext } from "../../contexts/ContextProvider";
import { SidebarData } from "./MenuData";
import { MdAccountCircle } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai"
import { Link } from "react-router-dom";
import MenuItem from "./MenuItem";
import { logout } from "../../services/auth.service";
import { UserData } from "./UserData";



const Navbar = () => {
  const { token, setUser, setToken, user } = useStateContext() ?? {};


  const functionHandler = (functionName: string) => {
    if (functionName == 'onLogout') onLogout();
  }
  const onLogout = () => {
    logout().then(({ data }) => {
      setUser?.(data.user);
      setToken?.(data.token)
    })
  }

  return (
    <div className="navbar">
      <Link to={"/"} className="navbar__logo">
        <h5>IN3dNet</h5>
      </Link>
      <div className="navbar__links">
        {SidebarData ? SidebarData.map((item, index) => (
          <MenuItem label={item.label} path={item.path} key={index} />
        )) : null}
      </div>

      <div className="navbar__icon">
        <div className="nav-item dropdown" style={{ 'display': token ? 'inline' : 'none' }}>
          <a id="navbarDropdown" className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-pre="">
            {user?.name}
          </a>
          <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            {UserData ? UserData.map((item, index) => (
              <Link to={item.url} key={index} className="dropdown-item" onClick={() => functionHandler(item.function ?? '')}>
                {item.text} {item.icon}
              </Link>
            )) : null}
          </div>
        </div>
        <Link to={"/login"}>
          <MdAccountCircle style={{ 'display': token ? 'none' : 'inline' }} />
        </Link>
      </div>
      <div className="navbar__menu ">
        <a id="navbarDropdown" className="nav-link dropdown-toggle " href="#" role="button"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-pre="" style={{ 'display': token ? 'inline' : 'none' }}>
          {user?.name}
        </a>
        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" >
          {UserData ? UserData.map((item, index) => (
            <Link to={item.url} key={index} className="dropdown-item" onClick={() => functionHandler(item.function ?? '')}>
              {item.text} {item.icon}
            </Link>
          )) : null}
        </div>
        <button className="btn btn-primary sidebar__button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
          <AiOutlineMenu />
        </button>
        <Link to={"/login"}>
          <MdAccountCircle style={{ 'display': token ? 'none' : 'inline' }} />
        </Link>
      </div>
    </div>
  );
}
export default Navbar;