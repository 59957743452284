import axios, { AxiosRequestConfig, AxiosHeaders, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios";

enum StatusCode {
    Unauthorized = 401,
    Forbidden = 403,
    TooManyRequests = 429,
    InternalServerError = 500,
    AuthorizationError = 422
}
const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Credentials": true,
    "X-Requested-With": "XMLHttpRequest",
};

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,

    headers,
})
axiosClient.interceptors.request.use((config: InternalAxiosRequestConfig ) => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`);
    return config;
});

axiosClient.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
    return response
}, (error) => {
    const { response } = error;
    return handleError(response);

})


const handleError = (error: AxiosError) => {
    const { status } = error;
    switch (status) {
        case StatusCode.Unauthorized:
            localStorage.removeItem('ACCESS_TOKEN');
            break;
        case StatusCode.Forbidden:

            break;
        case StatusCode.TooManyRequests:

            break;
        case StatusCode.InternalServerError:
        
            break;
        case StatusCode.AuthorizationError:
            
            break;
    }
    return Promise.reject(error);
}

export default axiosClient;