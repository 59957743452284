import { Navigate } from "react-router-dom"
import { useStateContext } from "../../../../contexts/ContextProvider";
import { blogjs } from "../../../../utils/blog.js"
import { GiMaterialsScience } from 'react-icons/gi'
import { FcTreeStructure } from 'react-icons/fc';
import React, { useState, useEffect } from 'react';
export const AdminBlogCms = () => {
  const { adminCmsToken } = useStateContext() ?? {};
  if (!adminCmsToken) {
    return <Navigate to="/admin/blog/cms/" />
  }
  const contentElement = document.querySelector('#content');
  contentElement?.addEventListener('DOMSubtreeModified', blogjs.handleBuildDomTree);
  return (
    <div>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-2 col-md-2 col-xl-2 px-sm-2 px-0 bg-dark">
            <div className="row flex-nowrap">
              <div className="col-xl-1">
                <div className="sidebar bg-dark mt-4 pt-4">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link active" href="#">
                        <FcTreeStructure />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">2</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">1</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">1</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-11">
                <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                  <div className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-5 d-none d-sm-inline">Components</span>
                  </div>
                  <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="menu">
                    <li className="nav-item w-100">
                      <div className="nav-link align-middle px-0 component" draggable="true" onDragStart={event => event.dataTransfer.setData('text/plain', 'header')}>
                        <span className="ms-1 d-none d-sm-inline">Header</span>
                      </div>
                    </li>
                    <li className="nav-item w-100">
                      <div className="nav-link align-middle px-0 component" draggable="true" onDragStart={event => event.dataTransfer.setData('text/plain', 'paragraph')}>
                        <span className="ms-1 d-none d-sm-inline">Paragraph</span>
                      </div>
                    </li>
                    <li className="nav-item w-100">
                      <div className="nav-link align-middle px-0 component" draggable="true" onDragStart={event => event.dataTransfer.setData('text/plain', 'image')}>
                        <span className="ms-1 d-none d-sm-inline">Image</span>
                      </div>
                    </li>
                    <li className="nav-item w-100">
                      <div className="nav-link align-middle px-0 component" draggable="true" onDragStart={event => event.dataTransfer.setData('text/plain', 'div')}>
                        <span className="ms-1 d-none d-sm-inline">Div</span>
                      </div>
                    </li>
                    <li className="nav-item w-100">
                      <div className="nav-link align-middle px-0 component" draggable="true" onDragStart={event => event.dataTransfer.setData('text/plain', 'dropdown')}>
                        <span className="ms-1 d-none d-sm-inline">DropDown</span>
                      </div>
                    </li>
                  </ul>
                  <div className="dropdown pb-4">
                    <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownOptions" data-bs-toggle="dropdown" aria-expanded="false">
                      <GiMaterialsScience size={30} />
                      <span className="d-none d-sm-inline mx-1">Options</span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownOptions">
                      {/* <li><a className="dropdown-item" href="#">New project...</a></li>
                  <li><a className="dropdown-item" href="#">Settings</a></li>
                  <li><a className="dropdown-item" href="#">Profile</a></li> */}
                      <li>
                        <br className="dropdown-divider" />
                      </li>
                      <li><a className="dropdown-item" href="#" onClick={() => blogjs.exportCode()}>Export code</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6 col-xl-7 px-sm-2 px-0 py-3">
            <h2>CMS System</h2>
            <div id="editor">
              <div id="toolbar">
                <button id="bold" onClick={blogjs.bold}>Bold</button>
                <button id="italic" onClick={blogjs.italic}>Italic</button>
                <button id="underline" onClick={blogjs.underline}>Underline</button>
                <button id="strike" onClick={blogjs.strike}>Strikethrough</button>
                <button id="link" onClick={blogjs.link}>Link</button>
                <button id="code" onClick={blogjs.code}>Code</button>
                <button id="quote" onClick={blogjs.quote}>Quote</button>
              </div>
              <div id="content" className="page" contentEditable="true" onDragOver={event => event.preventDefault()} onDrop={event => blogjs.dropHandler(event)}>

              </div>
            </div>
            <pre id="domTree"></pre>
          </div>
          <div className="col-1 col-md-3 col-xl-3 px-sm-2 px-0 bg-dark ">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
              <div className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <span className="fs-5 d-none d-sm-inline">CSS Styles</span>
              </div>
              <div className="popup w-100" id="popup">
                <textarea className="w-100" id="css" onInput={blogjs.compositeHandleOnCssInput} onKeyDown={blogjs.handleCssKeydown}></textarea>
                <div id="intellisense"></div>
                <button onClick={blogjs.saveCssPopup} className="btn btn-outline-primary me-1 mt-3">Save</button>
                <button onClick={blogjs.eraseCssPopup} className="btn btn-outline-warning mt-3 me-1">Erase</button>
                <button onClick={blogjs.dismissCssPopup} className="btn btn-outline-danger mt-3">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}