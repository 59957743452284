import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useOfferContext } from '../../contexts/OffersContext';
export const OfferInformation = () => {
    const { show, _setShow, activeOffer} = useOfferContext() ?? {};
    const handleShow = () => {
        _setShow?.(true);
    }
    const handleClose = () => {
        _setShow?.(false)
    }

    return (
        <div className="offers__card mt-2">
            <div className="card-body offers__card-content offers__card-shadow-box">
                {activeOffer?.id}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{activeOffer?.id}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>

    )
}