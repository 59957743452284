


export const Printer = () => {


    return(
        <div>
            Printer
        </div>
    )
}