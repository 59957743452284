import { IoLogOutOutline } from "react-icons/io5";
import { MdLocalOffer } from "react-icons/md";
import { Tb3DCubeSphere } from "react-icons/tb";
export const UserData = [
    {
        'text': 'Logout',
        'icon': <IoLogOutOutline />,
        'function': 'onLogout',
        'url': ''
    },
    {
        'text': 'New offer',
        'icon': <MdLocalOffer />,
        'function': '',
        'url': '/newOffer'
    },
    {
        'text': 'Printer',
        'icon': <Tb3DCubeSphere />,
        'function': '',
        'url': '/printer'
    },
];
