import { useScrollLoading } from "../useScrollLoading";

// Loader.tsx
type LoaderProps = Pick<
useScrollLoading,
 "isLoading" | "loadMoreCallback" | "isLastPage"
>;
 
export const Loader = ({
 isLoading,
 isLastPage,
 loadMoreCallback,
}: LoaderProps) => {
 if (isLoading) return <p>Loading...</p>;
 
 if (isLastPage) return <p></p>;
 
 return <div ref={loadMoreCallback}>load more callback</div>;
};