import axiosClient from "../axios-client"

interface NewOffer {
    title: string
    printer_name: string
    description: string
}

const newOffer = async (newOffer: NewOffer) => {
    return await axiosClient.post('/newOffer', newOffer)
}

const getOffers = async (url:string = '/getOffers') => {
    return await axiosClient.get(url??'/getOffers')
}

export {newOffer, getOffers}