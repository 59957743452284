
export const SidebarData = [
  {
    id: 1,
    label: "Home",
    path: "/",
  },
  {
    id: 2,
    label: "About",
    path: "/about",
  },
  {
    id: 3,
    label: "Contact",
    path: "/contact",
  },
  {
    id: 4,
    label: "Blog",
    path: "/blog",
  },
  

];
