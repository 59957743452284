import { useLayoutEffect, useState } from 'react';


const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);
  const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
  };
  useLayoutEffect(() => {
    const updateSize = (): void => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', debounce(updateSize, 250));
    updateSize();
    return (): void => {
        window.removeEventListener('resize', updateSize);
       
    }
  }, []);

  return isMobile;
};

export default useIsMobile;