import { createBrowserRouter } from 'react-router-dom'
import { Login } from './views/auth/Login'
import { NotFound } from './views/404/NotFound'
import { DefaultLayout } from './layout/DefaultLayout'
import { GuestLayout } from './layout/GuestLayout'
import { Signup } from './views/auth/Signup'
import { Printer } from './views/users/Printer'
import { Main } from './views/Main'
import { NewOffers } from './components/OffersComponents/NewOffers'
import { Blog } from './views/blog/Blog'
import { Testowanie } from './views/blog/Testowanie'
import { BlogList } from './views/blog/BlogList'
import { BlogMain } from './views/blog/admin/index/BlogMain'
import { AdminBlogLogin } from './views/blog/admin/AdminBlogLogin'
import {AdminBlogCms} from './views/blog/admin/cms/AdminBlogCms'


const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <NotFound />,
  },
  {
    path: '/blog',
    element: <Blog />,
    children:[
      {
        index: true,
        element: <BlogList/>
      },
      {
        path:':id',
        element: <Testowanie/>
      }
    ]
  },
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        path: '/newOffer',
        element: <NewOffers />
      },
      {
        path: '/printer',
        element: <Printer />
      },
    ]
  },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/signup',
        element: <Signup />
      },
    ]
  },
  {
    path: '/admin/blog/cms',
    element: <BlogMain />,
    children:[
      {
        index: true,
        element: <AdminBlogLogin/>
      },
      {
        path:':path',
        element: <AdminBlogCms/>
      }
    ]
  },
])

export default router